import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { InputText } from "primereact/inputtext";
// import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";

import Header from "../Header";

import "./Operation.css";

import { useGetAllTreatmentQuery } from "../../services/treatment/treatmentServices";
import { useGetFilteredCustomersQuery } from "../../services/customer/customerServices";
import { sendTreatment } from "../../features/treatment/treatmentActions";
import { contactAgainCustomer } from "../../features/customer/customerActions";
import { FilterMatchMode } from "primereact/api";
// import { MultiSelect } from "primereact/multiselect";

const SendTreatment = () => {
  const [selectedProducts, setSelectedProducts] = useState(null);
  // const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [contactAgainId, setContactAgainId] = useState(null);
  const [contactAgainVisible, setContactAgainVisible] = useState(false);
  const [sortedTreatments, setSortedTreatments] = useState([]);
  // const [cities, setCities] = useState([]);
  // const [states, setStates] = useState([]);
  const [filters] = useState({
    state: { value: null, matchMode: FilterMatchMode.IN },
    town: { value: null, matchMode: FilterMatchMode.IN },
    location: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const { sendTreatmentLoading } = useSelector((state) => state.treatment);

  const dispatch = useDispatch();

  const toast = useRef(null);

  const locationOptions = [
    "Nassau or Suffolk County",
    "Other",
  ];

  const {
    data: filteredCustomers,
    isLoading: filteredCustomersLoading,
    refetch,
  } = useGetFilteredCustomersQuery("filteredCustomersDetails", {
    refetchOnMountOrArgChange: true,
  });

  const { data: treatmentsData } = useGetAllTreatmentQuery(
    "treatmentsDetails",
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const defaultValues = {
    title: "",
    description: "",
    year: "",
    yesIds: [],
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm({ defaultValues });

  const selectedTitle = watch("title");

  useEffect(() => {
    if (selectedTitle) {
      const selectedTreatment = treatmentsData.data.find((treatment) => {
        return treatment?.title === selectedTitle.name;
      });
      if (selectedTreatment) {
        setValue("description", selectedTreatment.description);
      }
    }
  }, [selectedTitle, setValue, treatmentsData]);

  useEffect(() => {
    if (treatmentsData?.data) {
      const activeItems = treatmentsData?.data.filter(
        (item) => item.is_active === true
      );

      // Sort treatmentsData.data based on created_at field
      const sorted = activeItems?.slice().sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      setSortedTreatments(sorted);
    }
  }, [treatmentsData]);

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <></>
    );
  };

  const onSubmit = (data) => {
    const ids = selectedProducts && selectedProducts.map((item) => item.id);
    const obj = { ...data, yesIds: ids };
    const reqObj = {
      treatment_id: obj.title.code,
      cust_id_arr: obj.yesIds,
      dont_send_mail: [],
    };

    dispatch(sendTreatment(reqObj)).then((res) => {
      if (res?.payload?.data?.status === "Success") {
        reset();
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
    reset();
  };

  const locationFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={locationOptions}
        onChange={(e) => {
          const selectedValue = e.value;
          options.filterCallback(selectedValue === "" ? null : selectedValue);
        }}
        // optionLabel="name"
        className="p-column-filter"
        placeholder="Select a Location"
        showClear
      />
    );
  };

  const header = () => {
    return (
      <div className="flex items-center w-full">
        <div className="flex items-center justify-between w-full gap-2 my-auto">
          <Controller
            name="title"
            control={control}
            rules={{ required: "Title is required." }}
            render={({ field, fieldState }) => (
              <div className="w-3/12 my-auto">
                <div className="flex items-center w-full">
                  <span className="w-full">
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={sortedTreatments.map((treatment) => ({
                        name: treatment.title,
                        code: treatment.id,
                      }))}
                      optionLabel="name"
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      placeholder="Treatment"
                    />
                  </span>
                </div>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
        </div>
      </div>
    );
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <div>
        {rowData.first_name} {rowData.last_name}
      </div>
    );
  };

  const phoneBodyTemplate = (rowData) => {
    return <div>{rowData.phone}</div>;
  };

  const prevTreatmentBodyTemplate = (rowData) => {
    const { prev_treatements } = rowData;
    return (
      <div className="text-sm">
        <i
          className={`${classNames("pi", {
            "true-icon pi-check-circle text-blue-600":
              prev_treatements === true,
            "false-icon pi-times-circle text-red-500":
              prev_treatements === false,
            "pi-minus-circle text-gray-600": prev_treatements === null,
          })}`}
        ></i>
      </div>
    );
  };

  const onShowContactAgain = (id) => {
    setContactAgainId(id);
    setContactAgainVisible(true);
  };

  const actionsBodyTemplate = (rowData) => {
    const { id } = rowData;
    return (
      <div>
        <Button
          label="DNC"
          severity="danger"
          text
          onClick={(e) => {
            e.preventDefault();
            onShowContactAgain(id);
          }}
        />
      </div>
    );
  };

  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setContactAgainVisible(false)}
        className="p-button-text"
        text
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={(e) => {
          e.preventDefault();
          contactAgainHandler();
        }}
        text
      />
    </div>
  );

  const contactAgainHandler = () => {
    dispatch(contactAgainCustomer({ id: contactAgainId })).then((res) => {
      if (res?.payload?.data?.status === "Success") {
        refetch();
        setContactAgainVisible(false);
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  return (
    <div className={`h-screen py-4 pr-4`}>
      <Toast ref={toast} />
      <Dialog
        header="Delete"
        visible={contactAgainVisible}
        style={{ width: "50vw" }}
        onHide={() => setContactAgainVisible(false)}
        footer={footerContent}
      >
        <p className="m-0 text-center">
          Are you sure to not contact this customer again?
        </p>
      </Dialog>
      <div className="p-4 rounded-3xl h-full bg-[#ffeded]">
        <div className="flex flex-col justify-between h-full">
          <Header title={"Send Treatment"} />
          <div className="w-full h-full px-6 m-2 rounded-2xl">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="p-2 rounded-xl filtered-customer-table">
                <DataTable
                  value={filteredCustomers?.data}
                  selectionMode={"checkbox"}
                  loading={filteredCustomersLoading}
                  selection={selectedProducts}
                  onSelectionChange={(e) => setSelectedProducts(e.value)}
                  dataKey="id"
                  tableStyle={{ minWidth: "100%" }}
                  header={header}
                  paginator
                  rows={25}
                  filters={filters}
                  rowsPerPageOptions={[10, 25, 50]}
                  scrollable
                  scrollHeight="50vh"
                >
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3rem" }}
                  />
                  <Column
                    field="full_name"
                    header="Full Name"
                    body={nameBodyTemplate}
                  />
                  <Column
                    field="location"
                    header="Location"
                    filter
                    filterPlaceholder="Search by Location"
                    filterElement={locationFilterTemplate}
                    showFilterMatchModes={false}
                  />
                  {/* <Column
                    field="town"
                    header="City"
                    filter
                    filterPlaceholder="Search by City"
                    filterElement={citiesFilterTemplate}
                    showFilterMatchModes={false}
                  />
                  <Column
                    field="state"
                    header="State"
                    filter
                    filterPlaceholder="Search by State"
                    filterElement={statesFilterTemplate}
                    showFilterMatchModes={false}
                  /> */}
                  <Column
                    field="phone"
                    header="Phone"
                    body={phoneBodyTemplate}
                  />
                  <Column
                    field="prev_treatements"
                    header="Previous Treatment"
                    body={prevTreatmentBodyTemplate}
                  />
                  <Column header="Actions" body={actionsBodyTemplate} />
                </DataTable>
              </div>
              <div className="flex items-center justify-center mt-4 ">
                <Button
                  type="submit"
                  label="Send Treatment"
                  className="rounded-xl bg-[#C4E1D8] text-[#3A5D4C] border-[#3A5D4C]"
                  loading={sendTreatmentLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendTreatment;
